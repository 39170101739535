// GetAQuote.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './GetAQuote.css';

const GetAQuote = ({ bgColor, iconColor, textColor }) => {
  const bgStyle = {
    backgroundColor: bgColor,
  };

  const iconSrc = `images/icons/sendmail_${iconColor}.svg`;

  const textStyle = {
    color: textColor,
  };

  return (
    <div className='get-a-quote row' style={bgStyle}>
      <div className='col-2'>{/* Left spacing column */}</div>
      <div className='col-8'>
        <div className='row '>
          <div className='col'>
            <div className='d-flex justify-content-md-start justify-content-center align-items-center'>
              <img className='d-none d-md-flex' src={iconSrc} alt='icon' />
              <p className='text-center text-md-center' style={textStyle}>
                GET A QUOTE
              </p>
            </div>
          </div>

          <div className='col d-flex justify-content-md-end justify-content-center  align-items-center'>
            <NavLink to='/contact-us'>
              <div className='yellow-button'>Get A Quote</div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className='col-2'>{/* Left spacing column */}</div>
    </div>
  );
};

export default GetAQuote;
