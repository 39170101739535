import React from 'react';
import './Dropdown.css'; // Import your CSS file for styling

const Dropdown = ({ options, onSelect }) => {
  const handleSelect = (e) => {
    const selectedOption = e.target.value;
    onSelect(selectedOption);
  };

  return (
    <select className='dropdown' onChange={handleSelect} defaultValue='All'>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
