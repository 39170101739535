// Products.js
import React, { useState, useEffect } from 'react';
import './Services.css';
import $ from 'jquery';
import Card from '../../components/Card/Card';
import DetailCard from '../../components/DetailCard/DetailCard';
import CaseStudyCard from '../../components/CaseStudyCard/CaseStudyCard';
import GetAQuote from '../../components/GetAQuote/GetAQuote';

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';

import tabs from '../../data/services';

const Services = ({ selectedTabFromMenu }) => {
  const [activeTab, setActiveTab] = useState(selectedTabFromMenu);
  const [activeSubTab, setActiveSubTab] = useState(activeTab.caseStudies[0]);

  useEffect(() => {
    const handleTabClick = (tabId) => {
      const selectedTab = tabs.find((tab) => tab.id === tabId);
      setActiveTab(selectedTab);
      setActiveSubTab(selectedTab.caseStudies[0]);
    };

    $('.tab').click(function () {
      var selectedTabId = $(this).attr('id');
      handleTabClick(selectedTabId);
    });

    $('.subtab').click(function () {
      var selectedSubTabId = $(this).attr('id');
      setActiveSubTab(
        activeTab.caseStudies.find((cs) => cs.id === selectedSubTabId)
      );
    });
  }, [tabs, activeTab]);

  return (
    <>
      <section className='page-starter'>
        <div class='row'>
          <div class='col-12'>
            <h1 class='text-center text-white'>Our Services</h1>
          </div>
        </div>
        <div className='row tabs mt-5'>
          <div className='col-1'></div>
          <div className='col'>
            <div className='row'>
              {tabs.map((tab, index) => (
                <div
                  key={tab.id}
                  className={`col-12 col-md tab ${
                    tab.id == activeTab.id ? 'active' : ''
                  }`}
                  id={tab.id}
                >
                  <h4>{tab.title}</h4>
                </div>
              ))}
            </div>
          </div>
          <div className='col-1'></div>
        </div>
      </section>

      <section>
        <div className='row detailCardCont'>
          <div className='col-1'></div>
          <div className='col-10'>
            <ScrollAnimation
              startImmediately={true}
              threshold={-1}
              animation='slideInBottom'
              animationDelay={0}
            >
              <DetailCard
                iconSrc='/images/icons/gear_white.svg'
                title={activeTab.title}
                imageSrc={activeTab.imageSrc}
                description={activeTab.desc}
              />
            </ScrollAnimation>
          </div>
          <div className='col-1'></div>
        </div>
      </section>

      <ScrollAnimation
        startImmediately={false}
        threshold={200}
        animation='fadeIn'
        animationDelay={0}
      >
        <section>
          <div class='row'>
            <div class='col-12'>
              <h2 class='text-center'>Success Stories</h2>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-1'></div>
            <div className='col-10'>
              <div className='overflow-x-auto subtabs justify-content-center'>
                {activeTab.caseStudies.map((caseStudy, index) => (
                  <div
                    key={caseStudy.id}
                    className={`subtab ${
                      caseStudy.id == activeSubTab.id ? 'active' : ''
                    }`}
                    id={caseStudy.id}
                  >
                    {caseStudy.title}
                  </div>
                ))}
              </div>
            </div>
            <div className='col-1'></div>
          </div>
        </section>

        <section>
          <div className='caseStudyCardCont'>
            <CaseStudyCard
              title={activeSubTab.name}
              imageSrc={activeSubTab.imageSrc}
              description={activeSubTab.desc}
              pdfSrc={activeSubTab.pdfSrc}
            />
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation
        startImmediately={false}
        threshold={1100}
        animation='slideInBottom'
        animationDelay={0}
      >
        <section className='mt-3'>
          <GetAQuote
            bgColor='var(--light-gray)'
            iconColor='blue'
            textColor='var(--blue)'
          ></GetAQuote>
        </section>
      </ScrollAnimation>
    </>
  );
};

export default Services;
