const tabs = [
  {
    id: 'pcs',
    title: 'Process Control System',
    desc: '<p>In a diverse range of industries, from chemical to fertilizer production, we are committed to delivering a proven and highly reliable Programmable Logic Controller (PLC) platform, backed by years of successful operational experience.<p>',
    imageSrc: '/images/services/process_control_system.jpg',
    caseStudies: [
      {
        id: 'pcs1',
        title: 'Case Study 1',
        name: 'Flocculation & Coagulation Project',
        desc: {
          scopeOfWork: [
            'Design reliable PLC and SCADA system',
            'Installation PLC and SCADA system',
            'Programming PLC and SCADA system',
            'Commissioning',
          ],
          scopeOfSupply: [
            'M580 redundant CPU and IO PLC system',
            'Citect SCADA system',
            'Computer for engineer and operator workstation',
            'PLC panel control panel',
          ],
          introduction:
            "<p>We provide services to Indonesia's leading fertilizer manufacturer, known for its expertise in producing NPK fertilizer. To meet the high demand for fertilizer products and enhance the <span>efficiency and optimization</span> of the production process, a new <span>coagulation</span> and <span>flocculation</span> process plant is set to be constructed. To support this initiative, we, PT. Global Multi Solusi, as the System Integrator of PT Schneider Indonesia, have stepped in to provide a reliable PLC and SCADA control solution.</p>",
          challenges:
            "<p>For this new coagulation and flocculation process plant, we need to deliver not only a reliable monitoring and control system but also a <span>flexible system</span> that can be easily expanded according to the needs of the field device. All of this can be achieved using Schneider PLC and SCADA solutions. By offering a <span>control solution</span> using the Schneider PLC system, PT. Global Multi Solusi can meet the requirements of the control system and provide a <span>reliable</span> and <span>flexible</span> solution to our customers. In addition to the reliable PLC system we offer to ensure the production process runs smoothly, a good control system must also be easily <span>monitored</span> and <span>controlled</span>. That's why we require a SCADA system as the operator workstation interface.</p>",
          solution:
            "<p>To meet the customer's requirements, PT. Global Multi Solusi offers the <span>Schneider M580 PLC system</span> and <span>Citect SCADA system</span>. The M580 PLC control system features a flexible I/O arrangement, numerous communication capabilities, and a reliable and robust control system that can rival those offered by other brands. This control system also includes the Citect SCADA system, which is easy to monitor, user-friendly, and offers enhanced functionality.</p>",
          solutionDetails: '',
          conclusion:
            '<p>With our control system solution, the fertilizer company operates their coagulant and flocculation plant. Monitoring is done through hardwire or Modbus communication, and expansion is easily achieved by adding new I/O as needed. Our <span>satisfactory</span> performance, supported by Schneider Electric Indonesia, PT. Global Multi Solusi ensures that our client has the <span>optimal</span> control system solution.</p>',
        },
        imageSrc: '/images/case_study/petrokimia_gresik.jpeg',
        pdfSrc:
          '/pdfs/Project Flokulasi & Koagulasi - PT Petrokimia Gresik.pdf',
      },
      {
        id: 'pcs2',
        title: 'Case Study 2',
        name: 'Burner Management System for Gas Dehydration Unit In Oil & Gas Plant',
        desc: {
          scopeOfWork: [
            'The implementation of gas dehydration unit will improve the quality of the gas produce',
            'Reliable process control system in Gas Dehydration Unit will improve the capacity cos there’re no serious problem in this control system',
            'The control system being standard example of modeling for other control system in Pertamina Gas Plant',
            'Open system to allow integration at all levels',
          ],
          scopeOfSupply: [],
          introduction:
            'Our client is one of the largest gas company that engaged in the midstream and downstream sectors of the gas industry in Indonesia. This company is a subsidiary of PT. Pertamina (Persero) in the business role of gas trading, gas transportation, gas processing and gas distribution, as well as other business related to natural gas and its derivatives.  To improve the quality and capacity of the gas produced, our client built Gas Dehydration Units in West Java, Indonesia.',
          challenges:
            '<ul><li>The implementation of the gas dehydration unit will improve the quality of the gas produced.</li><li>A reliable process control system in the Gas Dehydration Unit will improve the capacity, as there are no serious problems in this control system.</li><li>The control system serves as a standard example of modeling for other control systems in the Pertamina Gas Plant.</li><li>It is an open system designed to allow integration at all levels.</li></ul>',
          solution:
            'The focus in the control burner management system is safety and reliable process control. The burner management system will provide all the features for safe burner process control and manage related interlocks. PT. Global Multi Solusi implemented these concepts into the control burner management system for the Glycol Regeneration Section in the Gas Dehydration Unit.',
          solutionDetails: '',
          conclusion:
            'By improving gas quality, we improved the price of gas produced by our client. Our control system facilitates diagnostic processes to ease troubleshooting, thus reducing unit downtime. Additionally, our solutions enable centralized data management by seamlessly integrating gas dehydration unit information into the DCS for comprehensive status monitoring and accurate product calculations. Furthermore, our Burner Management System ensures safe startup processes and manages related interlocks, prioritizing safety at every stage.',
        },
        imageSrc: '/images/case_study/burner_management_system.png',
        pdfSrc: '/pdfs/Burner Management System for PT Pertamina.pdf',
      },
    ],
  },
  {
    id: 'bh',
    title: 'Bulk Handling',
    desc: 'Delivering comprehensive solutions across the entire automation landscape, our offerings embrace a complete automation system that seamlessly integrates functionalities from jetty operations to bulk storage management. This includes cutting-edge technologies for ship loader/unloader, stacker/reclaimer, and conveyor control systems, ensuring a holistic and efficient approach to meet the diverse needs of modern industrial operations.',
    imageSrc: '/images/services/bulk_handling.jpg',
    caseStudies: [
      {
        id: 'bh1',
        title: 'Case Study 1',
        name: 'Bulk Material System for Coal Terminal',
        desc: {
          scopeOfWork: [],
          scopeOfSupply: [],
          introduction:
            '<p>We provide service to one of the largest coal mining company in Kalimantan island. They engage in the development of a <span>coal network infrastructure</span> project, including a coal breaker, Coal Preparation Plant (CPP), and Overland Conveyor (OLC), providing integrated coal distribution services. The OLC and Tanjung Bara Coal Terminal (TBCT) in Tanjung Bara, East Kalimantan, have an asset status and consist of coal crushing facilities, stockpiling, and an overland conveyor.</p>',
          challenges:
            '<ul><li>The implementation of the Coal Terminal will manage the loading and unloading of coal.</li><li>The coal conveyor will provide the passage of coal from the ship to storage and vice versa.</li><li>Monitoring speed and load will be done using an encoder and load cell by Merrick Industries.</li><li>The conveyor will operate on a sequential process through an automation system to provide safety and interlocks in coal transport.</li></ul>',
          solution:
            '<p>The focus of the Bulk Material system is on the safe loading and unloading of coal through the conveyor system. The conveyor management system will facilitate <span>comprehensive monitoring</span> of the coal processing, including both incoming and outgoing processes, and manage the related interlocks. PT. Global Multi Solusi implemented these concepts into the belt scale conveyor system for the Coal Terminal of our client.</p>',
          solutionDetails: '',
          conclusion:
            "<p>The enhanced Bulk Material System marks a <span>significant advancement</span> for out client’s coal transport processes. The incorporation of a conveyor system, equipped with Merrick Industrial's encoder, enables <span>efficient monitoring</span> of coal transport speed during both loading and unloading processes, streamlining the movement from storage to ships and vice versa. This system also allows for <span>real-time monitoring</span> of coal loads in the Coal Terminal, providing comprehensive status updates and accurate calculations of the total product in coal transport. Moreover, the Bulk Material System offers the <span>flexibility</span> of both manual and automatic process sequencing for conveyor in and out processes, along with the management of related interlocks, ensuring a <span>safer</span> and more <span>controlled</span> operation.</p>",
        },
        imageSrc: '/images/case_study/coal_terminal.jpg',
        pdfSrc: '/pdfs/PT. GMS, Success Story BMS, Indonesia, 2015.docx.pdf',
      },
    ],
  },
  {
    id: 'ws',
    title: 'Weighing System',
    desc: 'Specializing in <span>customized</span> weighing systems designed for a wide range of <span>bulk materials</span> and applications, our solutions are designed to <span>precisely</span> meet the <span>unique handling</span> requirements of your specific operations.',
    imageSrc: '/images/services/weighing_system.jpg',
    caseStudies: [
      {
        id: 'ws1',
        title: 'Case Study 1',
        name: 'Process Automation and Raw Material Feeding System for Phosphate Plant',
        desc: {
          scopeOfWork: [
            'Design reliable Weight Feeder, PLC and SCADA system',
            'Installation Weight Feeder PLC and SCADA system',
            'Programming PLC and SCADA system',
            'Commissioning',
          ],
          scopeOfSupply: [
            'Mechanical Weight Feeder System',
            'Genetix weighing control system',
            'M340 CPU and IO PLC system',
            'Citect SCADA system',
            'Computer for engineer and operator workstation',
            'PLC control panel ',
          ],
          introduction:
            'We serve as a trusted partner to an Indonesian fertilizer company that produce Phosphate fertilizer. To support production process in a high demand for fertilizer product, a new Phosphate fertilizer process plant are going to be built so that the production process can be more optimize. We PT. Global Multi Solusi, as the System Integrator of PT Schneider Indonesia and as Exclusive Representative of Merrick in Indonesia, came in and offered a reliable process weighing system and automation PLC and SCADA control solution.',
          challenges:
            'For this new Phosphate fertilizer process plant, we need to deliver not only a reliable monitoring and control system but also a flexible system that can be easily expanded according to the needs of the field device.',
          solution:
            'To answer the need of the customer, PT. Global Multi Solusi offer Merrick Genetix weighing control system, Schneider M340 PLC system and Citect SCADA system. Genetix weighing control system come with user friendly weighing application, communication capability, reliable and robust continuous weighing control system. M340 PLC control system come with flexible I/O arrangement, a lot of communication capability, reliable and robust control system that can match any other control system offered by other brand. This control system also come with Citect SCADA system which is an easy monitored, user friendly and have more functionality.',
          solutionDetails: '',
          conclusion:
            'With our weighing and control system solution, our client able to run their new Phosphate fertilizer process plant in effective and efficient. They can monitor and control every field instrument device and local system in the plant using hard wire or communication. When they think they need to expand their IO, they can easily add new IO as much as they need.',
        },
        imageSrc: '/images/case_study/saprotan_utama_nusantara.png',
        pdfSrc:
          'pdfs/Weighing and PLC Phosphate - PT Saprotan Utama Nusantara.pdf',
      },
    ],
  },
];

export default tabs;
