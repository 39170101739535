// ScrollAnimation.js
import React, { useState, useEffect } from 'react';
import './ScrollAnimation.css';

const ScrollAnimation = ({
  threshold,
  children,
  startImmediately,
  animation,
  animationDelay,
}) => {
  const [isVisible, setIsVisible] = useState(startImmediately);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(top > threshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  return (
    <div
      className={`scroll-animation ${
        isVisible ? animation : ''
      } delay-${animationDelay}`}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
