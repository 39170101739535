const featuredProducts = [
  {
    id: 'merrick17',
    name: 'Model 450',
    category: 'Weigh Belt Feeder Systems',
    desc: 'The Model 450 is built to handle a wide range of bulk materials, including coal, minerals, limestone, alternative fuels, and ores, even in the most challenging environments. Its durability, ensured by oversized components and robust construction, guarantees years of continuous service. With over 100 years of research and development, the Model 450 offers precise control of feed rate, accurate measurement of total weight, and reliable maintenance of set points.',
    imageSrc:
      'https://merrick-inc.com/images/products/weigh-belt-feeders/450-weigh-belt-feeder.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-450-heavy-duty-weigh-feeder',
  },
  {
    id: 'merrick14',
    name: 'Model 455MM Micro Millennium',
    category: 'Weigh Belt Feeder Systems',
    desc: "Owning a gravimetric weigh belt feeder from MERRICK ensures your facility benefits from our dedication to material handling accuracy and reliability. We know our clients depend on these feeders for consistent performance in tough conditions. That's why we're proud of our GRAVIMERIK® Model 455 Micro Millennium weigh belt feeder solutions.",
    imageSrc:
      'https://merrick-inc.com/images/products/weigh-belt-feeders/455mm-weigh-belt-feeder.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-455mm-micro-millennium-compact-weigh-feeder',
  },
  {
    id: 'merrick11',
    name: 'Model 475 NTEP Approved',
    category: 'Belt Scales',
    desc: 'Belt Widths up to 84”, Capacities to 11,000 TPH, Certifiable accuracy – legal for trade, Modular design, Easy installation, No maintenance, MERRICK quality and service',
    imageSrc:
      'https://merrick-inc.com/images/products/475-belt-scale-1000x750.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/belt-scales/model-475-conveyor-belt-scale',
  },
  {
    id: 'merrick9',
    name: 'Model 496 NFPA',
    category: 'Weigh Belt Feeder Systems',
    desc: 'The Model 496 NFPA rated Gravimetric feeder is designed to meet your needs when feeding a pulverizer. It is designed with the operator in mind to give years of trouble free operation.',
    imageSrc:
      'https://merrick-inc.com/images/products/weigh-belt-feeders/496-weigh-belt-feeder1000.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-496-nfpa-gravimetric-coal-feeder',
  },
  {
    id: 'merrick1',
    name: 'Model 970',
    category: 'Weigh Belt Feeder Systems',
    desc: 'The Model 970 can be designed for a 12" or 24" belt. Both belts are designed to meter various materials into a process at a designed feedrate with gravimetric precision. The feeder belt speed is varied to deliver material from a supply device into a process at a desired rate. A weight signal output and speed signal output are supplied for closed-loop process control.',
    imageSrc:
      'https://merrick-inc.com/images/products/weigh-belt-feeders/970-weigh-belt-feeder.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-970-weigh-belt-feeder',
  },
  {
    id: 'merrick3',
    name: 'Model 570 Gravimerik',
    category: 'Loss-In-Weigh Feeders',
    desc: 'A Loss in Weight Feeder is a device or collection of devices, which receives material from an upstream supply and meters that material into a downstream process at a set feedrate based on feedback from a weigh sensing device.',
    imageSrc:
      'https://merrick-inc.com/images/products/loss-in-weight/model-570-loss-in-weight-feeder.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/loss-in-weight-feeders/model-570-loss-in-weight-feeder',
  },
  {
    id: 'merrick18',
    name: 'Model 300 Impact Flowmeter',
    category: 'Flow Meter Systems',
    desc: "MERRICK's Model 300 Flowmeter is designed to provide reliable and accurate flow-measurement of granular or pulverized material at relatively high flow rates.",
    imageSrc:
      'https://merrick-inc.com/images/products/flowmeter/flowmeter300.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/flow-meter-systems/model-300-heavy-duty-impact-flowmeter',
  },
  {
    id: 'merrick21',
    name: 'Genetix Systems',
    category: 'Controls',
    desc: 'Genetix systems, displays, and controllers',
    imageSrc:
      'https://merrick-inc.com/images/products/controllers/Genetix-control_module-600x400.jpg',
    src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/controls-instrumentation-connectivity/genetix-systems-controls',
  },
];

export default featuredProducts;
