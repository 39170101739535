const tabs = [
  {
    id: 'merrick',
    title: 'Merrick Industries',
    description:
      '<p>Since 1908, MERRICK Industries offers a wide selection of premium quality <span>weighing systems</span> that handle a variety of dry <span>bulk materials</span> and applications. From cement to food products, power generation, mining, pharmaceuticals, steel, plastics, paper, chemicals, building materials, and even wastewater treatment, our systems produce results that are not only exceptionally <span>accurate</span> but consistently repeatable as well.</p>',
    logoSrc:
      'https://merrick-inc.com/images/merrick-industries-process-mfg-clr64.png',
    categories: [
      'All',
      'Weigh Belt Feeder Systems',
      'Loss-In-Weigh Feeders',
      'Belt Scales',
      'Valves',
      'Controls',
    ],
    products: [
      {
        id: 'merrick1',
        name: 'Model 970',
        category: 'Weigh Belt Feeder Systems',
        desc: 'The Model 970 can be designed for a 12" or 24" belt. Both belts are designed to meter various materials into a process at a designed feedrate with gravimetric precision. The feeder belt speed is varied to deliver material from a supply device into a process at a desired rate. A weight signal output and speed signal output are supplied for closed-loop process control.',
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/970-weigh-belt-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-970-weigh-belt-feeder',
      },
      {
        id: 'merrick2',
        name: 'Model 960',
        category: 'Weigh Belt Feeder Systems',
        desc: "The Model 960 designs, created in the 1990s, were tailored for water and wastewater treatment's demanding requirements. They address unique chemical metering challenges with input from industry experts, offering reliability, longevity, and simplicity. These feeders, adaptable for both gravimetric and volumetric applications, are commonly used to meter pebble lime into Slakers, with capacities ranging from 400 to 10,000 lbs/hr.",
        imageSrc:
          'https://merrick-inc.com/images/products/water-treatment/Model%20960%201000.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-960-water-treatment-applications',
      },
      {
        id: 'merrick3',
        name: 'Model 570 Gravimerik',
        category: 'Loss-In-Weigh Feeders',
        desc: 'A Loss in Weight Feeder is a device or collection of devices, which receives material from an upstream supply and meters that material into a downstream process at a set feedrate based on feedback from a weigh sensing device.',
        imageSrc:
          'https://merrick-inc.com/images/products/loss-in-weight/model-570-loss-in-weight-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/loss-in-weight-feeders/model-570-loss-in-weight-feeder',
      },
      {
        id: 'merrick4',
        name: 'Model 540 Liquid',
        category: 'Loss-In-Weigh Feeders',
        desc: '',
        imageSrc: '',
      },
      {
        id: 'merrick5',
        name: 'Model 530 Vibratory',
        category: 'Loss-In Weigh Feeders',
        desc: 'The Model 530 can be used to feed free flowing material into a process at designated feed rates with gravimetric precision. It can also be utilized to deliver a set amount of weight (batch) into a process. The vibratory discharge device of the feeder is operated at a variable speed to deliver material from the feeder hopper into a process.',
        imageSrc:
          'https://merrick-inc.com/images/products/loss-in-weight/model-530-loss-in-weight-feeder-food.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/loss-in-weight-feeders/model-530-vibratory-loss-in-weight-feeder',
      },
      {
        id: 'merrick6',
        name: 'Model 520 Economical',
        category: 'Loss-in-Weigh Feeders',
        desc: 'The Model 520 is MERRICK’s economical Loss-in-Weight System consisting of a Volumetric Feeder, Platform Scale and Genetix® Controller. A Loss-in-Weight Feeder is a device or collection of devices, which receives material from an upstream supply and meters that material into a downstream process at a set feedrate based on feedback from a weigh-sensing device.',
        imageSrc:
          'https://merrick-inc.com/images/products/loss-in-weight/model-520-loss-in-weight-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/loss-in-weight-feeders/model-520-economical-loss-in-weight-system',
      },
      {
        id: 'merrick7',
        name: 'Model 510 Conversion Kit',
        category: 'Loss-In-Weigh Feeders',
        desc: '',
        imageSrc: '',
        src: '',
      },
      {
        id: 'merrick9',
        name: 'Model 496 NFPA',
        category: 'Weigh Belt Feeder Systems',
        desc: 'The Model 496 NFPA rated Gravimetric feeder is designed to meet your needs when feeding a pulverizer. It is designed with the operator in mind to give years of trouble free operation.',
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/496-weigh-belt-feeder1000.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-496-nfpa-gravimetric-coal-feeder',
      },
      {
        id: 'merrick10',
        name: 'Model 490 Low Pressure',
        category: 'Weigh Belt Feeder Systems',
        desc: 'The Model 490 NFPA rated gravimetric feeder is designed to meet your needs when feeding a fluidized bed boiler or other system needs requiring a low pressure, sealed feeder. It is designed with the operator in mind to give years of trouble free operation.',
        imageSrc:
          'https://merrick-inc.com/images/products/power-industries/model-490-power-generation1000.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-490-nfpa-gravimetric-coal-or-limestone-feeder',
      },
      {
        id: 'merrick11',
        name: 'Model 475 NTEP Approved',
        category: 'Belt Scales',
        desc: 'Belt Widths up to 84”, Capacities to 11,000 TPH, Certifiable accuracy – legal for trade, Modular design, Easy installation, No maintenance, MERRICK quality and service',
        imageSrc:
          'https://merrick-inc.com/images/products/475-belt-scale-1000x750.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/belt-scales/model-475-conveyor-belt-scale',
      },
      {
        id: 'merrick12',
        name: 'Model 475 EZ',
        category: 'Belt Scales',
        desc: 'Fits Any Size Conveyor, Troughed or Flat Belt Idlers, Easy Installation, Low Cost, No Maintenance, Merrick Quality and Service',
        imageSrc:
          'https://merrick-inc.com/images/products/heavy-industry/475EZ-belt-scale-1000-color.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/belt-scales/model-475ez-conveyor-belt-scale',
      },
      {
        id: 'merrick13',
        name: 'Model 475 Calib Ease',
        desc: '',
        imageSrc: '',
        src: '',
      },
      {
        id: 'merrick14',
        name: 'Model 455MM Micro Millennium',
        category: 'Weigh Belt Feeder Systems',
        desc: "Owning a gravimetric weigh belt feeder from MERRICK ensures your facility benefits from our dedication to material handling accuracy and reliability. We know our clients depend on these feeders for consistent performance in tough conditions. That's why we're proud of our GRAVIMERIK® Model 455 Micro Millennium weigh belt feeder solutions.",
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/455mm-weigh-belt-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-455mm-micro-millennium-compact-weigh-feeder',
      },
      {
        id: 'merrick15',
        name: 'Model 455MM Millennium Weigh Feeder',
        category: 'Weigh Belt Feeder Systems',
        desc: 'Owning a MERRICK gravimetric weigh belt feeder guarantees accuracy and reliability in material handling. Our GRAVIMERIK® Model 455 Millennium solutions ensure consistent performance in harsh conditions. With over a century of expertise in weigh feeding and our invention of dynamic weighing, MERRICK feeders are built to last and perform reliably for years.',
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/model-455m-1000.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-455m-millennium-weigh-feeder',
      },
      {
        id: 'merrick16',
        name: 'Model 450A Apron Feeder',
        category: 'Weigh Belt Feeder Systems',
        desc: 'Our Model 450A Apron Weigh Feeder offers a custom designed feeding solution that pairs the rugged conveying capability of an apron belt with precise and dependable gravimetric weighing control. Additionally, our upgrading and modernization capabilities allow older weigh feeders (regardless of make) to be retrofitted with the latest technology which bolsters efficiency, improves accuracy and ensures long-term reliability.',
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/450A-weigh-belt-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-450a-apron-weigh-feeder',
      },
      {
        id: 'merrick17',
        name: 'Model 450',
        category: 'Weigh Belt Feeder Systems',
        desc: 'The Model 450 is built to handle a wide range of bulk materials, including coal, minerals, limestone, alternative fuels, and ores, even in the most challenging environments. Its durability, ensured by oversized components and robust construction, guarantees years of continuous service. With over 100 years of research and development, the Model 450 offers precise control of feed rate, accurate measurement of total weight, and reliable maintenance of set points.',
        imageSrc:
          'https://merrick-inc.com/images/products/weigh-belt-feeders/450-weigh-belt-feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/weigh-belt-feeder-systems/model-450-heavy-duty-weigh-feeder',
      },
      {
        id: 'merrick18',
        name: 'Model 300 Impact Flowmeter',
        category: 'Flow Meter Systems',
        desc: "MERRICK's Model 300 Flowmeter is designed to provide reliable and accurate flow-measurement of granular or pulverized material at relatively high flow rates.",
        imageSrc:
          'https://merrick-inc.com/images/products/flowmeter/flowmeter300.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/flow-meter-systems/model-300-heavy-duty-impact-flowmeter',
      },
      {
        id: 'merrick19',
        name: 'Orbital Loss-In-Weigh Feeder',
        category: 'Loss-In-Weigh Feeders',
        desc: 'A single Orbital Feeder provides excellent Loss in Weight control and accurate metering of material. Its greatest strengths are even more apparent when groups of Orbital Feeders are used to simultaneously feed multiple materials into a blending process. Hoppers, augers, agitation, spouts, and motor drives can be configured within a production run as needed.',
        imageSrc:
          'https://merrick-inc.com/images/products/loss-in-weight/Orbital-Feeder.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/loss-in-weight-feeders/loss-in-weight-feeders',
      },
      {
        id: 'merrick20',
        name: 'Valves',
        category: 'Valves',
        desc: 'MERRICK Knife gate valves can be utilized as isolation valves located at the feeder outlet, pulverizer outlet or in the burner line at the furnace face. These valves provide “off-on” control of the material of varying sizes.',
        imageSrc:
          'https://merrick-inc.com/images/products/valves/knife-valve.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/valves',
      },
      {
        id: 'merrick21',
        name: 'Genetix Systems',
        category: 'Controls',
        desc: 'Genetix systems, displays, and controllers',
        imageSrc:
          'https://merrick-inc.com/images/products/controllers/Genetix-control_module-600x400.jpg',
        src: 'https://merrick-inc.com/products-belt-feeders-weight-gravimetric-lime-slakers-mixers-valves-controls/controls-instrumentation-connectivity/genetix-systems-controls',
      },
    ],
  },
  {
    id: 'rockwell',
    title: 'Rockwell Automation',
    description:
      "Rockwell Automation, Inc. is a leading American supplier of <span>industrial automation</span> and <span>digital transformation</span> solutions. It stands as the world's foremost company solely dedicated to industrial automation and information technology.",
    logoSrc:
      'https://www.rockwellautomation.com/content/dam/rockwell-automation/sites/images/logos/2019_Logo_rgb_RA_Bug-LeftText_color.svg',
    categories: ['All'],
    products: [
      {
        id: 'rockwell1',
        name: 'ControlLogix 5580 Controllers',
        desc: 'Our ControlLogix® and GuardLogix® 5580 family of controllers use the Studio 5000® design environment as the standard framework that optimizes productivity, reducing time to commission. This framework manages Integrated Motion over EtherNet/IP for high-speed motion applications and SIL2/PLd and SIL3/PLe safety solutions.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/16x9-controllogix-guardlogix-5580-controller-family.2400.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/large-controllers/controllogix/1756controllogix5580.html',
      },
      {
        id: 'rockwell2',
        name: 'CompactLogix 5380 Controllers',
        desc: 'Our CompactLogixTM and Compact GuardLogix® 5380 controllers offer improved performance, increased capacity, enhanced productivity, and better security to meet the growing needs of smart manufacturing equipment. They utilize the Studio 5000 Logix Designer® application, enabling collaborative system design and maintenance. This common environment streamlines efficiency, reduces commissioning time, and manages integrated motion over EtherNet/IPTM for high-speed applications and up to SIL 3/PLe safety solutions. These controllers are perfect for applications requiring high- performance communications, I/O, and motion control for up to 32 axes.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/16x9-compact-guardLogix-5380-sil3-compact-guardlogix-5380-sil2-compactlogix-5380.2400.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/small-controllers/compactlogix-family/compactlogix-5380-controllers.html',
      },
      {
        id: 'rockwell3',
        name: 'PanelView Plus 7',
        desc: 'Our Bulletin 2711P PanelViewTM Plus 7 Graphic Terminals come in standard and performance versions, with the latter available in conformal coat, stainless steel, and on- machine (ArmorViewTM Plus 7) options for flexibility. Build your application using FactoryTalk® View Machine Edition to simplify configuration and enhance your Integrated Architecture® solution. These terminals feature Ethernet connectivity and support remote monitoring via VNC and FactoryTalk ViewPoint connectivity.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/PanelViewPlus7-4in.1920.png',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/hmi/graphic-terminals/2711p-panelview-plus-7.html',
      },
      {
        id: 'rockwell4',
        name: 'SLC 500 Controllers',
        desc: 'SLC™ 500 control platform is used for a wide variety of applications. Rockwell Automation has announced that some SLC 500 Bulletin numbers are discontinued and no longer available for sale. Customers are encouraged to migrate to our newer CompactLogix™ 5370 or 5380 control platforms.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/1747-slc500controllefamily-front1-large-312w255h.312.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/small-controllers/slc-500-controllers.html',
      },
      {
        id: 'rockwell5',
        name: 'CompactLogix 5370 Controllers',
        desc: 'CompactLogix™ 5370 Controllers are part of the Integrated Architecture® system. These controllers include integrated safety, and use the same programming software, network protocol, and information capabilities as all Logix controllers. This provides a common development environment for all control disciplines. Consistent tools and features help users to lower engineering investment costs, ease diagnostics and troubleshooting, and speed up time to market for small to mid-size applications.',
        imageSrc:
          'https://www.rockwellautomation.com/content/dam/rockwell-automation/sites/images/products/allenbradley/gl/programmable-controllers/1768-69-compactlogix/controllers/5370_Controller_Family.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/small-controllers/compactlogix-family/compactlogix-5370-controllers.html',
      },
      {
        id: 'rockwell6',
        name: 'ControlLogix 5570 Controllers',
        desc: 'Our ControlLogix® 5570 Controllers are available in standard, safety, extreme temperature, and On-Machine models suitable for process, motion, discrete, and high-availability applications. As part of our Integrated Architecture™ system, these controllers use the Studio 5000 Automation Engineering & Design Environment™ and common network protocols. These high-performance controllers provide a common control engine with a common development environment for all control disciplines.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/16x9-1756-l72erom-1756-l7x-test2.2400.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/large-controllers/controllogix/controllogix-standard-controllers.html',
      },
      {
        id: 'rockwell7',
        name: 'Studio 5000 Logix Designer',
        desc: 'Talk about the ultimate advantage — imagine the simplicity of seamlessly configuring, programming and maintaining the devices in your control system. We have you covered! Studio 5000 Logix Designer® software is a productivity win and your one-stop shop for managing the Allen‑Bradley Logix 5000™ family of controllers and related control system.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/Studio5000LogixDesigner_1515843725.2560.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/software/factorytalk/designsuite/studio-5000/studio-5000-logix-designer.html',
      },
      {
        id: 'rockwell8',
        name: 'PanelView 5000 Graphic Terminals',
        desc: 'Our PanelView™ 5000 Graphic Terminals offer an intuitive, modern design and provide enhanced Logix integration that uses Studio 5000 View Designer™ software. This integration lets engineers enter configuration information once and use it for the entire automation design. It also helps you build modern applications with high-speed HMI buttons for jogging applications, scalable vector graphics, and a pre-configured system banner that provides diagnostic information.',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/PanelView-5310.1920.png',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/hmi/graphic-terminals/panelview-5000.html',
      },

      {
        id: 'rockwell9',
        name: '2711R PanelView 800',
        desc: 'Our Bulletin 2711R PanelView™ 800 graphic terminals are panel-mounted display devices that offer keypad or touch screen options for operator input. These graphic terminals are available in 4...10 in. display sizes and are compatible with micro and small controllers. They feature high-performance processors, high-resolution displays, and high flash and dynamic memories. The built-in Ethernet and serial communication ports support a variety of networks. These terminals offer improved usability with remote monitoring capability.',
        imageSrc:
          'https://www.rockwellautomation.com/content/dam/rockwell-automation/sites/images/products/allenbradley/gl/medlrgprod/1485208074579-panelview800-2711r-group3--large-312w255h.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/hmi/graphic-terminals/2711r-panelview-800.html',
      },
      {
        id: 'rockwell10',
        name: 'FactoryTalk View Site Edition Software',
        desc: 'Forget about seizing the day. Seize the opportunity for a supervisory view! With a comprehensive picture of production status across entire lines and processes, FactoryTalk® View Site Edition (SE) lets you monitor and control manufacturing at all levels, from a single operator station up to distributed server/client, multi-user applications. FactoryTalk View Site Edition provides an accurate real-time view of operations, while meeting the demands of multiple stakeholders, including engineering, maintenance, operations, and production Information Technology (IT).',
        imageSrc:
          'https://rockwellautomation.scene7.com/is/image/rockwellautomation/FactoryTalkViewSiteEdition.2560.jpg',
        src: 'https://www.rockwellautomation.com/en-us/products/software/factorytalk/operationsuite/view/factorytalk-view-site-edition.html',
      },
    ],
  },
  {
    id: 'se',
    title: 'Schneider Electric',
    description:
      'Schneider Electric SE, a multinational corporation based in France, specializes in <span>digital automation</span> and <span>energy management</span> solutions. Its focus encompasses homes, buildings, data centers, infrastructure, and industries, utilizing a blend of energy technologies, real-time automation, software, and services.',
    logoSrc:
      'https://www.etim-international.com/wp-content/uploads/2022/04/Schneider-Electric-logo-jpg_16-9_white.png',
    categories: [
      'All',
      'Controllers for HVAC-R',
      'Distributed I/O',
      'Mature Offers',
      'PAC Programmable Automation Controllers',
      'PLC Programmable Logic Controllers',
      'Safety PLC Controllers',
    ],
    products: [
      {
        id: 'se1',
        name: 'Easy Modicon M100',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Easy Modicon M100 logic controllers: the cost-effective and easy solution to sequential control needs for your simple machines up to 40 I/O',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=PF142032&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62881-easy-modicon-m100/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se2',
        name: 'Easy Modicon M200',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Easy Modicon M200 logic controllers: the best performance/price ratio solutions for your small automation systems requiring flexibility and simple motion.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=PF142041&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62882-easy-modicon-m200/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se3',
        name: 'EcoStruxure™ Machine Expert Twin',
        category: 'PLC Programmable Logic Controllers',
        desc: 'EcoStruxure™ Machine Expert Twin is a digital twin software suite to create digital models of real machines. Before building the machine, you can start the virtual design, virtual commissioning, and agile development, enabling parallel engineering of mechanical, electrical and controls work assignments saving time to market by up to 50% and commissioning time up to 60%.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=EMET_CP211129&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/97196554-ecostruxure-machine-expert-twin/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se4',
        name: 'Zelio Logic SR2/SR3',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Easy to use, Zelio Logic provides a real alternative to solutions based on cabled logic or specific cards.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=SR_531_RPMFS17001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/531-zelio-logic-sr2-sr3/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se5',
        name: 'Zelio Soft',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Zelio Soft software for Zelio Logic smart relays (SR2/ SR3). Includes programming software, self-training module, application library, and technical instructions.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=ZS_542_RPFJR18001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/542-zelio-soft/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control#overview',
      },
      {
        id: 'se6',
        name: 'EcoStruxure Machine Expert (SoMachine)',
        category: 'PLC Programmable Logic Controllers',
        desc: 'EcoStruxure Machine Expert saves engineering time through intuitive machine programming with one of the most modern and powerful tool-based software concepts on the market.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=SoMach_2226_RPSCT17001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/2226-ecostruxure-machine-expert-somachine/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se7',
        name: 'PLC - Modicon M221',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Achieve benchmark performance while increasing profitability with the Modicon M221 PLC (programmable logic controller), the most complete controller in the new Modicon range',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=62128_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62128-plc-modicon-m221/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se8',
        name: 'Logic Controller - Modicon M241',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Achieve benchmark performance and embedded motion with the Modicon M241 logic controller, the most communicative controller on the market thanks to 5 embedded communication ports',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=62129_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62129-logic-controller-modicon-m241/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se9',
        name: 'Logic Controller - Modicon M251',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Increase flexibility, while saving space in your cabinet with the Modicon M251 logic controller for modular and distributed architectures.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=62130_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62130-logic-controller-modicon-m251/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se10',
        name: 'Modicon M262',
        category: 'PLC Programmable Logic Controllers',
        desc: 'Modicon M262 controllers embed Industrial Internet of Things (IIoT) protocols and encryption to provide direct cloud connectivity & digital services.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=M262_RP18002&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/65771-modicon-m262/?parent-subcategory-id=3910&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se12',
        name: 'EcoStruxure™ Control Expert',
        category: 'PAC Programmable Automation Controllers',
        desc: 'EcoStruxure Control Expert (formerly known as Unity Pro) is a unique software platform to increase design productivity and performance of your Modicon M340, M580 and M580 Safety, Momentum, Premium, Quantum applications.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Unity_548_RPSCT17001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/548-ecostruxure-control-expert/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se14',
        name: 'Modicon M340',
        category: 'PAC Programmable Automation Controllers',
        desc: 'Driving performance with simplicity and integrated functions',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Modicon_M340_range&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/1468-modicon-m340/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se15',
        name: 'Modicon M580 - ePac Controller',
        category: 'PAC Programmable Automation Controllers',
        desc: 'Modicon M580 ePAC is the controller which can help hybrid manufacturers achieve better, measurable*, earlier ROI and Up to 100% returns on their project investments in less than three months.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Modicon_M580_range&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62098-modicon-m580-epac-controller/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se16',
        name: 'Modicon X80 I/Os',
        category: 'PAC Programmable Automation Controllers',
        desc: 'All modules with X80 form factor: backplane, power supply, I/O digital, I/O analog, communication, expert, etc.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Modicon_X80_range-2022_image&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/61938-modicon-x80-i-os/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se18',
        name: 'EcoStruxure™ OPC UA Server Expert',
        category: 'PAC Programmable Automation Controllers',
        desc: 'A state of the art OPC UA communications platform linking industrial devices and Modicon PLCs to the Industrial Internet of Things (IIoT)',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=OPC_UA-Range-Image&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/66388-ecostruxure-opc-ua-server-expert/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se19',
        name: 'OPC Factory Server',
        category: 'PAC Programmable Automation Controllers',
        desc: 'Data server software',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=OPC_547_RPFJR18002&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/547-opc-factory-server/?parent-subcategory-id=3950&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se20',
        name: 'Preventa XPS',
        category: 'Safety PLC Controllers',
        desc: 'Safety modules',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=626_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/626-preventa-xps/?parent-subcategory-id=3960&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se21',
        name: 'Preventa XPS MC, XPS MP',
        category: 'Safety PLC Controllers',
        desc: 'Safety controllers',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=XPS_RP20049&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/1438-preventa-xps-mc-xps-mp/?parent-subcategory-id=3960&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se22',
        name: 'Preventa XPS MF',
        category: 'Safety PLC Controllers',
        desc: 'Safety PLCs',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=XPS_1439_RPFJR001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/1439-preventa-xps-mf/?parent-subcategory-id=3960&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se23',
        name: 'Modicon M580 - ePac Controller',
        category: 'Safety PLC Controllers',
        desc: 'Modicon M580 ePAC is the controller which can help hybrid manufacturers achieve better, measurable*, earlier ROI and Up to 100% returns on their project investments in less than three months.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Modicon_M580_range&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62098-modicon-m580-epac-controller/?parent-subcategory-id=3960&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se24',
        name: 'PLC controllers',
        category: 'Controllers for HVAC-R',
        desc: 'Compact, flexible and scalable,Modicon™ logic controllers are ideal for hard-wired solutions.',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=63031_Main_PLC_controllers&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/63031-plc-controllers/?parent-subcategory-id=3920&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se26',
        name: 'Advantys FTB, FTM',
        category: 'Distributed I/O',
        desc: 'IP67 distributed I/Os',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=614_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/614-advantys-ftb-ftm/?parent-subcategory-id=3930&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se27',
        name: 'Advantys Telefast ABE 7',
        category: 'Distributed I/O',
        desc: 'Pre-wired system enabling connection and adaptation of control signals of PLC cards equipped wuth HE10 connectors',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=ABE7_603_RPFJR18001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/603-advantys-telefast-abe-7/?parent-subcategory-id=3930&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se29',
        name: 'Modicon TM3',
        category: 'Distributed I/O',
        desc: 'Boost the performance of your controller with the Modicon TM3 I/O system specially designed for the Modicon M221, M241 and M251 logic controllers!',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=TM3_62131_RPMFS18006&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/62131-modicon-tm3/?parent-subcategory-id=3930&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se31',
        name: 'Modicon STB',
        category: 'Distributed I/O',
        desc: 'For distributed connection to sensors and actuators from a PLC with prefabricated cables',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=606-2_main&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/606-modicon-stb/?parent-subcategory-id=3930&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se36',
        name: 'Modicon Quantum',
        category: 'Mature Offers',
        desc: 'PLC for process applications',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=Quant_538_RPFJR18001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/538-modicon-quantum/?parent-subcategory-id=89180&filter=business-1-industrial-automation-and-control',
      },
      {
        id: 'se37',
        name: 'Advantys OTB',
        category: 'Mature Offers',
        desc: 'Simply optimum !',
        imageSrc:
          'https://download.schneider-electric.com/files?p_Doc_Ref=OTB_1055_RPFJR18001&p_File_Type=rendition_369_jpg',
        src: 'https://www.se.com/id/id/product-range/1055-advantys-otb/?parent-subcategory-id=89180&filter=business-1-industrial-automation-and-control',
      },
    ],
  },
  {
    id: 'lantech',
    title: 'Lantech',
    description:
      'With a track record in design and manufacturing of IP products, Lantech brings the best network solutions for <span>industrial and IP networks</span> to the marketplace. The Lantech group is devoted to providing end user benefits & feature rich innovative high-quality networking solutions. Lantech offers a complete range of <span>hardened</span> and <span>non-hardened</span> networking products in support of the global transition to all Ethernet services.',
    logoSrc:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR72cJiyIkMxy07kM_QZ8B1l7fjuhRfr7zfXwwEr-W39A&s',
    categories: [
      'All',
      'Industrial Managed Switches',
      'Industrial Rackmount Switches',
    ],
    products: [
      {
        id: 'lantech1',
        name: 'IGS-5408GSFP',
        category: 'Industrial Managed Switches',
        desc: 'Lantech IGS-5408GSFP is a high performance L2+ (Gigabit uplink) Ethernet switch with 8 10/100/1000T + 4 1000M SFP which provides L2 wire speed and advanced security function for network aggregation deployment. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN mode with easy configuration. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network. It also supports10K Jumbo frame.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IGS-5408GSFP.png',
        src: 'https://www.lantechcom.com.au/IGS-5408GSFP.html',
      },
      {
        id: 'lantech2',
        name: 'IGS-3408GSFP',
        category: 'Industrial Managed Switches',
        desc: 'Lantech IGS-3408GSFP is a high performance L2+ (Gigabit uplink) switch with 8 10/100/1000T + 4 1000M SFP which provides L2 wire speed and advanced security function for network aggregation deployment. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN model with easy configuration. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network. It also supports Cisco Discovery Protocol (CDP) for Ciscoworks to detect the switch info and show on L2 map topology.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IGS-3408GSFP.png',
        src: 'https://www.lantechcom.com.au/IGS-3408GSFP.html',
      },
      {
        id: 'lantech3',
        name: 'IES-3408GSFP',
        category: 'Industrial Managed Switches',
        desc: 'Lantech IES-5408DFT is a high performance L2+ (Gigabit uplink) switch with 8 10/100TX + 2 10/100/1000T + 2 Dual Speed SFP which provides L2 wire speed and advanced security function for network aggregation deployment. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN model with easy configuration. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network. It also supports Cisco Discovery Protocol (CDP) for Ciscoworks to detect the switch info and show on L2 map topology.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IES-5408DFT-U.png',
        src: 'https://www.lantechcom.com.au/IES-5408DFT.html',
      },
      {
        id: 'lantech4',
        name: 'IPGS-5424',
        category: 'Industrial Managed Switches',
        desc: 'Lantech IES-3408GSFP is a high performance L2+ (Gigabit uplink) switch with 8 10/100TX + 4 1000M SFP which provides L2 wire speed and advanced security function for network aggregation deployment. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN model with easy configuration. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network. It also supports Cisco Discovery Protocol (CDP) for Ciscoworks to detect the switch info and show on L2 map topology.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IES-3408GSFP.png',
        src: 'https://www.lantechcom.com.au/assets/img/products/IES-3408GSFP.png',
      },
      {
        id: 'lantech5',
        name: 'IES-5408DFT',
        category: 'Industrial Rackmount Switches',
        desc: 'Lantech IPGS-5424 is a high performance L2+ (Gigabit uplink) PoE managed Ethernet switch with 24 10/100/1000T PoE + 4 Dual Speed SFP. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN model. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network. Compliant with 802.3af/at standard, the Lantech IPGS-5424 is able to feed each PoE port up to 30 Watts@54 VDC providing the connected PD devices. Lantech IPGS-5424 supports advanced PoE management including PoE detection and scheduling. PoE detection can detect if the connected PD hangs then restart the PD; PoE scheduling is to allow pre-set power feeding schedule upon routine time table. Each PoE ports can be Enabled/disabled, get the voltage, current, Watt, and temperature info displayed on WebUI.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IPGS-5424.png',
        src: 'https://www.lantechcom.com.au/IPGS-5424.html',
      },
      {
        id: 'lantech6',
        name: 'IGS-5424',
        category: 'Industrial Rackmount Switches',
        desc: 'Lantech IGS-5424 is a high performance L2+ (Gigabit uplink) switch with 24 10/100/1000T + 4 Dual Speed SFP. It delivers ITU G.8032 enhanced ring recovery less than 20ms in single ring while also supports train ring, enhanced mode, multiple VLAN model. The comprehensive QoS, QoS by VLAN, advanced security including INGRESS/EGRESS ACL L2/L3, TACACS+**, SSH v2/SSL, Mac based DHCP server, DHCP Option 82, DHCP server, IGMPv1/v2/v3/router port, QinQ are supported and also required in large network.',
        imageSrc:
          'https://www.lantechcom.com.au/assets/img/products/IGS-5424.png',
        src: 'https://www.lantechcom.com.au/IGS-5424.html',
      },
    ],
  },
];

export default tabs;
