// ProductCard.js
import React from 'react';
import './ProductCard.css';

const maxWords = 10;

const getDescriptionPreview = (description) => {
  const words = description.split(' ');
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  } else {
    return description;
  }
};

const ProductCard = ({ id, title, imageSrc, description, onClick }) => {
  return (
    <div id={id} className='product-card' onClick={onClick}>
      <div className='imgCont d-flex justify content-center align-items-center'>
        <img src={imageSrc} alt={title} />
      </div>
      <div className='card-text my-2'>
        <p>
          <span>{title}</span>
        </p>
        <p>{getDescriptionPreview(description)}</p>
      </div>
    </div>
  );
};

export default ProductCard;
