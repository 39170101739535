import React, { useState, useEffect } from 'react';

const IncrementalNumber = ({
  startImmediately,
  threshold,
  targetNumber,
  suffix,
}) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;

      if (top > threshold) {
        animateNumber();
        window.removeEventListener('scroll', handleScroll);
      }
    };

    const animateNumber = () => {
      const increment = Math.ceil(targetNumber / 100); // Adjust increment for smoother animation
      const interval = setInterval(() => {
        setCurrentNumber((prev) => {
          const nextNumber = prev + increment;
          return nextNumber >= targetNumber ? targetNumber : nextNumber;
        });
      }, 15);

      return () => clearInterval(interval);
    };

    if (startImmediately) {
      animateNumber();
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [targetNumber]);

  return (
    <div>
      <p>
        {currentNumber}
        {suffix}
      </p>
    </div>
  );
};

export default IncrementalNumber;
