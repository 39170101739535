// AboutUs.js
import React from 'react';
import './AboutUs.css';

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';
import IncrementalNumber from '../../components/IncrementalNumber/IncrementalNumber';

import ProjectCardSlider from '../../components/ProjectCardSlider/ProjectCardSlider';

const AboutUs = () => {
  const projects = [
    {
      name: 'TNB JanaManjung Malaysia',
      desc: 'Coal Feeder Audit',
      imageSrc: '/images/project/TNB_JanaManjung_Malaysia.jpg',
    },
    {
      name: 'Pupuk Sriwidjaja',
      desc: 'Supply Weigh Feeder for Raw Material NPK',
      imageSrc: '/images/project/Pupuk_Sriwidjaya.jpg',
    },
    {
      name: 'PLN Nusantara Power',
      desc: 'Retrofit Coal Feeder Controller System',
      imageSrc: '/images/project/PLN_Nusantara_Power.jpg',
    },
    {
      name: 'Petrokimia Gresik',
      desc: 'Belt Scale System at Jetty',
      imageSrc: '/images/project/Petrokimia_Gresik.jpg',
    },
    {
      name: 'Bogasari',
      desc: 'Retrofit Belt Scale Wheat Silo',
      imageSrc: '/images/project/Bogasari.jpg',
    },
    {
      name: 'Antika Raya',
      desc: 'Catering Box Loading System for Aircraft',
      imageSrc: '/images/project/Antika_Raya.jpg',
    },
  ];
  return (
    <>
      <section class='page-starter'>
        <div class='row'>
          <div class='col-12'>
            <h1 class='text-center text-white'>About Us</h1>
          </div>
        </div>
        <div id='aboutUsStatistics' class='row mt-5'>
          <div className='col'>
            <div className='row'>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/building_white.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={true}
                      threshold={0}
                      targetNumber={2003}
                      suffix=''
                    />
                  </p>
                  <p className='stats-desc mt-0'>Year of Establishment</p>
                </div>
              </div>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/clipboard_check_white.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={true}
                      threshold={1400}
                      targetNumber={300}
                      suffix='+'
                    />
                  </p>
                  <p className='stats-desc mt-0'>Completed Projects</p>
                </div>
              </div>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/group_white.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={true}
                      threshold={1400}
                      targetNumber={400}
                      suffix='+'
                    />
                  </p>
                  <p className='stats-desc mt-0'>Total Clients Served</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' py-5' id='whoWeAre'>
        <div class='row'>
          <div class='col-2'></div>
          <div class='col-8'>
            <ScrollAnimation
              startImmediately={true}
              threshold={-1}
              animation='slideInTop'
              animationDelay={0}
            >
              <div class='row'>
                <div class='col-12 col-md-6'>
                  <h2 class='blue-text'>Who We Are</h2>
                  <p>
                    Founded in 2003, PT. Global Multi Solusi began its journey
                    in <span>automation system integration</span>. With a
                    committed and dynamic team, the company has grown to handle{' '}
                    <span>turnkey</span>
                    automation projects in major industries like Power
                    Generation,
                    <span>
                      Petrochemical, Fertilizer, Pulp & Paper, Oil & Gas,
                      Cement, and Mining
                    </span>
                    , and now, we're putting a <span>stronger focus</span> on{' '}
                    <span>process weighing automation</span>.
                  </p>
                </div>
                <div class='col-12 col-md-6'>
                  <img src='/images/employees/team_photo_logo.png' alt='' />
                </div>
              </div>
            </ScrollAnimation>
          </div>
          <div class='col-2'></div>
        </div>
      </section>

      <ScrollAnimation
        startImmediately={false}
        threshold={25}
        animation='slideInBottom'
        animationDelay={0}
      >
        <section id='visionMission'>
          <div class='row'>
            <div class='col-2'></div>
            <div class='col-8'>
              <div className='row'>
                <div className='col-12 col-md-6 p-2'>
                  <div className='gray-box'>
                    <img src='/images/icons/person.svg' alt='' />
                    <h2 class='blue-text mt-3'>Our Vision</h2>
                    <p>
                      To be a <span>trusted</span> partner in providing the{' '}
                      <span>best</span> solution for weighing systems and
                      industrial automation.
                    </p>
                  </div>
                </div>
                <div class='col-12 col-md-6 p-2'>
                  <div className='gray-box'>
                    <img src='/images/icons/person.svg' alt='' />
                    <h2 class='blue-text mt-3'>Our Mission</h2>
                    <p>To give our customer an added value and by providing:</p>
                    <ul>
                      <li>Good quality services.</li>
                      <li>Continuous improve skill and knowledge.</li>
                      <li>Solid communication and relationship.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-2'></div>
          </div>
        </section>
      </ScrollAnimation>

      <section>
        <div class='py-5' id='project-experience'>
          <div class='row'>
            <div className='col-2'></div>
            <div class='col'>
              <ScrollAnimation
                startImmediately={false}
                threshold={400}
                animation='slideInLeft'
                animationDelay={0}
              >
                <h2 class=''>Project Experience</h2>
              </ScrollAnimation>
            </div>
            <div className='col-2'></div>
          </div>
          <div class='row mt-4'>
            <div class='col-2'></div>
            <div class='col-8'>
              <div class=''>
                <div class='d-flex overflow-x-auto'>
                  <ProjectCardSlider data={projects} />
                </div>
              </div>
            </div>
            <div class='col-2'></div>
          </div>
        </div>
      </section>

      <section id='capabilities' class='py-5'>
        <div class='row'>
          <div class='col-2'></div>
          <div class='col-8'>
            <div class='row '>
              <ScrollAnimation
                startImmediately={false}
                threshold={800}
                animation='slideInLeft'
                animationDelay={0}
              >
                <h2 class='blue-text'>Our Capabilities</h2>
              </ScrollAnimation>
            </div>
            <div class='row mt-4'>
              <div class='col-12 col-md-6 p-2'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1300}
                  animation='slideInBottom'
                  animationDelay={0}
                >
                  <div class='textcard'>
                    <div class='card-text'>
                      <img src='/images/icons/bulk_handling.png' alt='' />
                      <h3 class=''>Control System Design</h3>
                      <p class=''>
                        Control System Design based on user specific{' '}
                        <span>requirements</span> and ensure the expected
                        outcome achieved
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class='col-12 col-md-6 p-2'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1300}
                  animation='slideInBottom'
                  animationDelay={0}
                >
                  <div class='textcard'>
                    <div class='card-text'>
                      <img
                        src='/images/icons/process_control_system2.png'
                        alt=''
                      />
                      <h3 class=''>Control Panel Assembly</h3>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div class='row'>
              <div class='col-12 col-md-6 p-2'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1300}
                  animation='slideInBottom'
                  animationDelay={0}
                >
                  <div class='textcard'>
                    <div class='card-text'>
                      <img
                        src='/images/icons/process_control_system.png'
                        alt=''
                      />
                      <h3 class=''>Commisioning</h3>
                      <p class=''>
                        We manage commissioning after finishing the project,
                        including FAT and SAT. Our team helps start up,
                        calibrate, and test, involving operators for
                        understanding
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class='col-12 col-md-6 p-2'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1300}
                  animation='slideInBottom'
                  animationDelay={0}
                >
                  <div class='textcard'>
                    <div class='card-text'>
                      <img
                        src='/images/icons/engineering_services.png'
                        alt=''
                      />
                      <h3 class=''>Training</h3>
                      <p class=''>
                        We offer customized training packages to ensure your
                        staff is fully equipped to operate and troubleshoot your
                        automation systems
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div class='col-2'></div>
        </div>
      </section>

      <section class='py-5 mt-5' id='values'>
        <div class='row'>
          <div class='col-2'></div>
          <div class='col-8'>
            <div class='row'>
              <div class='col'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1800}
                  animation='slideInTop'
                  animationDelay={0}
                >
                  <h2 class='blue-text text-center'>Our Values</h2>
                </ScrollAnimation>
              </div>
            </div>
            <ScrollAnimation
              startImmediately={false}
              threshold={1800}
              animation='scaleUp'
              animationDelay={0}
            >
              <div class='row mt-5 icons'>
                <div class='col d-flex flex-column'>
                  <img src='/images/icons/group_blue.svg' alt='' />
                  <p>
                    <span>Teamwork</span>
                  </p>
                </div>
                <div class='col d-flex flex-column'>
                  <img src='/images/icons/heart.svg' alt='' />
                  <p>
                    <span>Integrity</span>
                  </p>
                </div>
                <div class='col d-flex flex-column'>
                  <img src='/images/icons/chromecast.svg' alt='' />
                  <p>
                    <span>Proactive</span>
                  </p>
                </div>
              </div>

              <div className='row mt-1'>
                <div className='line-dot d-flex align-items-center justify-content-center'>
                  <img src='/images/icons/line-dot.png' alt='' />
                </div>
              </div>
            </ScrollAnimation>
          </div>
          <div class='col-2'></div>
        </div>
        <div className='row'>
          <div className='col-2'></div>
          <div className='col'></div>
          <div className='col-2'></div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
