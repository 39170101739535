// Modal.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Modal.css';

const Modal = ({ title, content, imgSrc, src, onClose }) => {
  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal d-flex justify-content-center align-items'>
        <button className='close-button' onClick={onClose}>
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='row content'>
          <div className='col-12 col-md-5 d-flex justify-content-center align-items-center'>
            <img src={imgSrc} alt='' />
          </div>
          <div className='col-12 col-md-7'>
            <h3>{title}</h3>
            <p className='mt-4 desc'>{content}</p>
            <div className='mt-4 d-flex'>
              <a className='' href={src} target='_blank' class=''>
                <div class='blue-button'>More Info</div>
              </a>
              <NavLink to='/contact-us'>
                <div class='yellow-button'>Get A Quote</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
