import React from 'react';
import './CaseStudyCard.css';

const CaseStudyCard = ({ title, imageSrc, description, pdfSrc }) => {
  const { introduction, challenges, solution, solutionDetails, conclusion } =
    description;

  var scopeOfWork = description.scopeOfWork ? description.scopeOfWork : [];
  var scopeOfSupply = description.scopeOfSupply
    ? description.scopeOfSupply
    : [];

  return (
    <div className='caseStudyCard container'>
      <div className='row text-center'>
        <h3>{title}</h3>
      </div>
      <div className='row justify-content-center mt-4 text-center text-md-start'>
        <div className='col-12 col-md-4 scope'>
          <div>
            <img className='img-fluid' src={imageSrc} alt='' />
          </div>
          {scopeOfWork.length > 0 && (
            <div>
              <h4 className='mt-3'>Scope Of Work</h4>
              <ul className=''>
                {scopeOfWork.map((work, index) => (
                  <li key={index}>{work}</li>
                ))}
              </ul>
            </div>
          )}
          {scopeOfWork.length > 0 && (
            <div>
              <h4 className='mt-3'>Scope Of Supply</h4>
              <ul className=''>
                {scopeOfSupply.map((supply, index) => (
                  <li key={index}>{supply}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className='col words'>
          <h4>Introduction</h4>
          <p dangerouslySetInnerHTML={{ __html: introduction }}></p>
          <h4>Challenges</h4>
          <p dangerouslySetInnerHTML={{ __html: challenges }}></p>
          <h4>Solution</h4>
          <p dangerouslySetInnerHTML={{ __html: solution }}></p>
          <p dangerouslySetInnerHTML={{ __html: solutionDetails }}></p>
          <h4>Conclusion:</h4>
          <p dangerouslySetInnerHTML={{ __html: conclusion }}></p>
          {pdfSrc.length > 0 && (
            <p>
              <a className='pdfButton' href={pdfSrc} download>
                Download PDF to read more
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
