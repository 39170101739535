import React from 'react';
import './DetailCard.css';

const DetailCard = ({ iconSrc, title, imageSrc, description }) => (
  <div className='detail-card row'>
    <div className='col mt-5 mt-md-0'>
      <div className='detail-card-text'>
        <div className='row card-title'>
          <div className='col-1'></div>
          <div className='col-10 d-flex py-3'>
            <div className='row'>
              <div className='col-1 justify-content-center'>
                <img className='icon' src={iconSrc} alt='icon' />
              </div>
              <div className='col'>
                <h4 className='text-white'>
                  <span>{title}</span>
                </h4>
              </div>
            </div>
          </div>
          <div className='col-1'></div>
        </div>
        <div className='row card-content'>
          <div className='col'>
            <div className='row py-3'>
              <div className='col-1'></div>
              <div className='col-10'>
                <div className='row'>
                  <div className='detail-card-img col-12 col-md-6'>
                    <img className='' src={imageSrc} alt='' />
                  </div>
                  <div className='col-12 col-md-6 mt-3 mt-md-0'>
                    <div className='row'>
                      <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

{
}

export default DetailCard;
