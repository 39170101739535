import React, { useState, useEffect } from 'react';
import './ProjectCardSlider.css';

const ProjectCardSlider = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(3);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCardsPerPage(1);
    } else {
      setCardsPerPage(2);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToNextPage = () => {
    setCurrentPage((currentPage) =>
      Math.min(currentPage + 1, Math.ceil(data.length / cardsPerPage) - 1)
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
  };

  return (
    <div className='p-card-slider-container container'>
      <div className='row'>
        <div
          className='p-card-slider'
          style={{ transform: `translateX(-${currentPage * 100}%)` }}
        >
          {data.map((item, index) => (
            <div key={index} className='card'>
              <div className=''>
                <img src={item.imageSrc} alt={item.title} />
              </div>
              <div className='card-text m-3'>
                <p className='mb-1'>
                  <span>{item.name}</span>
                </p>
                <p className='desc mt-0'>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='row mt-5 mt-md-4'>
        <div className='col d-flex justify-content-center justify-content-md-end'>
          <button className='prev' onClick={goToPrevPage}>
            ❮
          </button>
          <button className='next' onClick={goToNextPage}>
            ❯
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='pagination d-flex justify-content-center'>
          {[...Array(Math.ceil(data.length / cardsPerPage)).keys()].map(
            (page, index) => (
              <span
                key={index}
                className={`dot ${index === currentPage ? 'active' : ''}`}
                onClick={() => setCurrentPage(index)}
              ></span>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCardSlider;
