// ContactUs.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';

import './ContactUs.css';

const ContactUs = () => {
  return (
    <>
      <section className='page-starter'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='text-center text-white mb-5'>Contact Us</h1>
          </div>
        </div>
      </section>

      <section className='contacts py-5 mb-4'>
        <div className='row pt-5'>
          <div className='col-2'></div>
          <div className='col-8'>
            <div className='row'>
              <div className='col-md-6 gallery'>
                <img src='/images/employees/team_photo_collage.png' alt='' />
              </div>
              <div className='col-md-6 mt-3 mt-md-0 center'>
                <ScrollAnimation
                  startImmediately={true}
                  threshold={-1}
                  animation='fadeIn'
                  animationDelay={0}
                >
                  <h2>We Are Here to Help</h2>
                  <p>Please email us for more information</p>
                </ScrollAnimation>
                <ScrollAnimation
                  startImmediately={true}
                  threshold={-1}
                  animation='fadeIn'
                  animationDelay={0}
                >
                  <div className='row media'>
                    <div className='col-3 icon'>
                      <img src='/images/icons/mail.svg' alt='' />
                    </div>
                    <div className='col ml-2 '>
                      <p className='mb-0'>
                        <span>Email: </span>
                      </p>
                      <a
                        href='mailto:sales@globalmultisolusi.com'
                        target='_blank'
                      >
                        sales@globalmultisolusi.com
                      </a>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={10}
                  animation='fadeIn'
                  animationDelay={'quarter'}
                >
                  <div className='row media'>
                    <div className='col-3 icon'>
                      <img src='/images/icons/phone.svg' alt='' />
                    </div>
                    <div className='col ml-2 '>
                      <p className='mb-0'>
                        <span>Phone: </span>
                      </p>
                      <a target='_blank' href='tel:+62315664551'>
                        +62 31 566 4551
                      </a>{' '}
                      /{' '}
                      <a target='_blank' href='tel:+6282140465877'>
                        (+62) 8214 0465 877 (WA Only)
                      </a>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={10}
                  animation='fadeIn'
                  animationDelay={'half'}
                >
                  <div className='row media'>
                    <div className='col-3 icon'>
                      <img src='/images/icons/pinpoint.svg' alt='' />
                    </div>
                    <div className='col ml-2 '>
                      <p className='mb-0'>
                        <span>Address: </span>
                      </p>
                      <a
                        target='_blank'
                        href='https://www.google.com/maps/place/Jl.+Sriwijaya+No.23,+RT.002%2FRW.06,+Keputran,+Kec.+Tegalsari,+Surabaya,+Jawa+Timur+60265/@-7.2799337,112.7397032,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd7fbc3ac478799:0xab6116650e37e89c!8m2!3d-7.2799337!4d112.7422835!16s%2Fg%2F11c4jvf2vh'
                      >
                        Jl. Sriwijaya No.23 Surabaya, Jawa Timur 60265{' '}
                      </a>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className='col-2'></div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
