// ClientList.js
import React from 'react';
import './ClientList.css';

const ClientList = ({ clients }) => {
  const duplicatedClients = [...clients, ...clients];

  return (
    <div className='scroll'>
      <div className='m-scroll'>
        {duplicatedClients.map((client, index) => (
          <span key={index}>
            <img src={client.src} alt={client.alt} />
          </span>
        ))}
      </div>
    </div>
  );
};

export default ClientList;
