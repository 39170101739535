import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import Products from './pages/products/Products';
import Services from './pages/services/Services';
import ContactUs from './pages/contactUs/ContactUs';
import ScrollToTop from './components/ScrollToTop'; // Import the updated ScrollToTop component

import productTabs from './data/products';
import serviceTabs from './data/services';

const App = () => {
  const [activeProductTab, setActiveProductTab] = useState(productTabs[0]);
  const [activeServiceTab, setActiveServiceTab] = useState(serviceTabs[0]);

  return (
    <Router>
      <div className='app'>
        <Header
          setProductTabFromMenu={setActiveProductTab}
          setServiceTabFromMenu={setActiveServiceTab}
        />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route
            path='/products'
            element={<Products selectedTabFromMenu={activeProductTab} />}
          />
          <Route
            path='/services'
            element={<Services selectedTabFromMenu={activeServiceTab} />}
          />
          <Route path='/contact-us' element={<ContactUs />} />
          {/* Redirect any other unknown paths to the homepage */}
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
