import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

const Footer = () => {
  return (
    <>
      <footer class='footer'>
        <div class='row'>
          <div class='col'>
            <div class='row mb-5'>
              <a href='index.html'>
                <img class='logo' src='/images/logo/white.png' alt='' />
              </a>
            </div>
            <div class='row'>
              <div class='col-12 col-md-3'>
                <p>
                  <span>PT.Global Multi Solusi</span>
                </p>
                <a href='https://www.google.com/maps/place/Jl.+Sriwijaya+No.23,+RT.002%2FRW.06,+Keputran,+Kec.+Tegalsari,+Surabaya,+Jawa+Timur+60265/@-7.2799337,112.7397032,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd7fbc3ac478799:0xab6116650e37e89c!8m2!3d-7.2799337!4d112.7422835!16s%2Fg%2F11c4jvf2vh'>
                  Jl. Sriwijaya No.23 Surabaya, Jawa Timur 60265{' '}
                </a>
              </div>
              <div class='col-12 col-md-3'>
                <p>
                  <span>Contact Us</span>
                </p>
                <p>
                  <a href='mailto:sales@globalmultisolusi.com'>
                    sales@globalmultisolusi.com
                  </a>
                </p>
                <p>
                  <a href='tel:+62315664551'>+62 31 566 4551</a>
                </p>
              </div>
              <div class='col-12 col-md-3'>
                <p>
                  <span>Others</span>
                </p>
                <p>
                  <NavLink to='/services'>Solution</NavLink>
                </p>
                <p>
                  <NavLink to='/products'>Products</NavLink>
                </p>
              </div>
              <div class='col-12 col-md-3'>
                <p>
                  <span>Copyright</span>
                </p>
                <p>PT Global Multi Solusi</p>
                <p>Disclaimer</p>
                <p>Privacy policy</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
